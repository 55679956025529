<template>
  <main>
    <trac-loading v-if="loading"></trac-loading>

    <div v-else>
      <div @click="$router.go(-1)" class="flex cursor-pointer items-center">
        <div class="border border-opacity-25 rounded-md border-black">
          <svg class="w-5 h-5 fill-current text-black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z" />
          </svg>
        </div>
        <div class="ml-3 text-sm text-black">Back</div>
      </div>
      <h1 class="mt-5 text-lg font-bold">Create VFD Account</h1>
      <div class="w-4/6">
        <form class="shadow p-5 bg-white mt-10" @submit.prevent="submit">
          <h1 class="text-lg font-bold">New VFD Account</h1>
          <h1 class="text-sm mt-2">Enter details below</h1>

          <div class="grid grid-cols-2 gap-5 mt-10">
            <div v-for="(data, index) in loanData" :key="index">
              <div class="mb-3 text-sm text-gray-500 font-medium">
                {{ data.name }}
              </div>
              <div v-if="data.type === 'text' ||
                data.type === 'number' ||
                data.type === 'date' ||
                data.type === 'email'
                ">
                <input @keydown="onKeyDown" :required="data.required" :type="data.type"
                  class="py-3 text-sm leading-8 w-full border-2 text-gray-900 font-medium placeholder:text-gray-three pl-10 pr-4 block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                  v-model="form[data.payload]" />
              </div>
              <div v-if="data.type === 'select'">
                <select
                  class="py-5 px-6 leading-8 border-2 w-full text-gray-900 font-medium placeholder:text-gray-three pl-10 pr-10 block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                  v-model="form[data.payload]" :required="data.required">
                  <option v-for="(option, index) in data.option" :value="option.value" :key="index">
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="flex justify-end items-end mt-10">
            <button :disabled="isLoading" :class="isLoading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-900'
              " type="submit"
              class="' w-64 relative flex items-center justify-center px-12 py-4 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 ',">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      form: {
        loan_amount: "",
        tenor: 0,
        borrower_phone: "",
        borrower_name: "",
        company_name: "",
        rc_number: "",
        is_business_registered: false,
        date_of_incorporation: "",
        dob: "",
        bvn: "",
      },
      loanData: [
        {
          name: "Full Name",
          type: "text",
          required: true,
          payload: "borrower_name",
        },
        {
          name: " Date of Birth",
          type: "date",
          payload: "dob",
        },
        {
          name: "Phone Number",
          type: "number",
          required: true,
          payload: "borrower_phone",
        },

        {
          name: "BVN",
          type: "text",
          payload: "bvn",
        },
        {
          name: "Business Name",
          type: "text",
          required: true,
          payload: "company_name",
        },
        {
          name: "Loan Amount",
          type: "number",
          required: true,
          payload: "loan_amount",
        },
        {
          name: "RC Number",
          type: "text",
          payload: "rc_number",
        },

        {
          name: "Tenor",
          type: "number",
          payload: "tenor",
          required: true,
        },
        {
          name: "Is Business Registered",
          type: "select",
          required: true,
          payload: "is_business_registered",
          option: [
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ],
        },
        {
          name: " Incorporation Date",
          type: "date",
          payload: "date_of_incorporation",
        },
      ],
    };
  },
  methods: {
    onKeyDown(event) {
      if (event.key === '-') {
        event.preventDefault();
      }
    },
    async submit() {
      this.isLoading = true;
      this.form.loan_amount = parseInt(this.form.loan_amount);
      this.form.tenor = parseInt(this.form.tenor);
      this.form.date_of_incorporation = moment(
        this.form.date_of_incorporation
      ).format("DD MMMM YYYY");
      this.form.dob = moment(this.form.dob).format("DD MMMM YYYY");
      const res = await this.$store.dispatch("CREATE_VFD", this.form);
      if (!res.status) {
        alert(res.message);
        this.isLoading = false;
        return;
      }
      if (res.data.status) {
        alert(res.data.message);
        //CLEAR FORM
        this.form = {
          loan_amount: "",
          tenor: 0,
          borrower_phone: "",
          borrower_name: "",
          company_name: "",
          rc_number: "",
          is_business_registered: false,
          date_of_incorporation: "",
          dob: "",
          bvn: "",
        };
        this.$router.push({
          name: 'VFDPortal'
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
